// Scripts per modificar la mida de les imatges
import "./images-square.js";
import "./images-crop-min-height.js";

const $header = document.querySelector("header");
window.addEventListener("scroll", (e) => {
	if (window.scrollY > 0) {
		$header.classList.add("compressed");
	} else {
		$header.classList.remove("compressed");
	}
});

// Input Mask
Inputmask().mask(document.querySelectorAll("form input"));

//jQuery - Ens assegurem que el jquery estigui carregat
$(function () {
	/** SPINNER **/
	setTimeout(() => {
		$(".spinner").fadeOut();
	}, 500);

	// Tots els iframe responsius
	fitvids();

	//Treiem el WOW en responsive?
	if ($(window).width <= 768) {
		$(".wow").removeClass("wow");
	}

	//Afegim la classe "table" a totes les taules de les lliures
	$("#lliures table").addClass("table");

	//Si volem que el bótó de contactar vagi al formulari
	$("#btn-contactar").on("click", function () {
		$("html, body").animate(
			{
				scrollTop: $("#contactar").offset().top - 190,
			},
			1000
		);
	});

	//Afegim el "#" a la URL en el cas que tinguem tabs a les lliures
	$('a[data-toggle="tab"]').on("shown.bs.tab", function (e) {
		e.preventDefault();
		let $ele = $(e.target);
		window.history.replaceState(null, null, $ele.attr("href"));
	});
	let hash = window.location.hash;
	if (hash) {
		//Si hi ha tabs a la pàgina, seleccionem el tab a través del hash
		if ($("body").find(".nav").attr("role")) {
			$(hash + "-tab").tab("show");
		} else {
			//fem l'animació en scroll
			$("html, body").animate(
				{
					scrollTop: $(hash).offset().top - 190,
				},
				500
			);
		}
	}

	$("#fletxa_moviment").fadeIn(500).delay(500);

	$("#fletxa_moviment").on("click", function () {
		$("html, body").animate(
			{
				scrollTop: $("#contingut").offset().top - 134,
			},
			700
		);
	});

	//Afegim la classe "table" a totes les taules de les lliures
	$("#lliures table").not(".datatable").addClass("table");
	$("#lliures table")
		.not(".datatable")
		.not(".custom-table")
		.addClass("table-striped");
	$("#lliures table").not(".datatable").css("border", "none");

	//Per afegir el fancybox al cliclar les imatges
	$("[data-fancybox]").fancybox({
		// Options will go here
	});

	//Scroll back to top
	var offset = 0;
	var duration = 500;
	jQuery(window).scroll(function () {
		if (jQuery(this).scrollTop() > offset) {
			jQuery(".back-to-top").fadeIn(duration);
		} else {
			jQuery(".back-to-top").fadeOut(duration);
		}
	});

	jQuery(".back-to-top").click(function (event) {
		event.preventDefault();
		jQuery("html, body").animate({ scrollTop: 0 }, duration);
		return false;
	});

	// *********** MENU ************ //

	let windowWidth = $(window).width();
	$(window).resize(function () {
		windowWidth = $(window).width();
	});

	$(".sf-menu").superfish({
		autoArrows: true,
		cssArrows: true,

		//Per fer mostrar els submenús a l'esquerra o a la dreta de la pantalla defepent si hi caben
		onBeforeShow: function () {
			if (!this.is(".sf-with-ul>li>ul")) {
				var subMenuWidth = $(this).width();
				var parentLi = $(this).parent();
				if (parentLi.offset()) {
					var parentWidth = parentLi.width();
					var subMenuRight =
						parentLi.offset().left + parentWidth + subMenuWidth;
					if (subMenuRight > windowWidth) {
						if ($(this).parents("li").length >= 2) {
							$(this).css("left", "-" + parentWidth);
						} else {
							$(this).css({ left: "auto", right: "0px" });
						}
					} else {
						$(this).css({ left: "", right: "" });
					}
				}
			}
		},
	});

	/* Hack per sinó ens fa el link */
	$(".sf-with-ul").on("click", function (e) {
		e.preventDefault();
		let link = $(this).attr("href");
		if (link) window.location.href = link;
	});

	$("#mobnav-btn").click(function () {
		$(".sf-menu").toggleClass("xactive");
	});

	$(".mobnav-subarrow").click(function () {
		$(this).parent().toggleClass("xpopdrop");

		//Comprovem quin icona ha de sortir
		var positiu = $(this).children("i").hasClass("fa-plus");
		if (positiu) {
			$(this).children("i").removeClass("fa-plus");
			$(this).children("i").addClass("fa-minus");
		} else {
			$(this).children("i").removeClass("fa-minus");
			$(this).children("i").addClass("fa-plus");
		}
	});

	// ****** VISOR IMATGES LLIURES *****
	// iniciem sliders
	const sliders = document.querySelectorAll(".slider-lliures");
	if (sliders) {
		sliders.forEach((slide) => {
			new Swiper(slide, {
				initialSlide: 0,
				loop: true,
				autoplay: {
					delay: 5000,
				},
				spaceBetween: 30,
				effect: "fade",
				navigation: {
					nextEl: ".swiper-button-next",
					prevEl: ".swiper-button-prev",
				},
				pagination: {
					el: ".swiper-pagination",
					clickable: true,
				},
			});
		});
	}

	const owlsCarousels = document.querySelectorAll(".owl-carousel");
	if (owlsCarousels) {
		owlsCarousels.forEach((owl) => {
			$(owl).owlCarousel({
				loop: owl.dataset.autoplay === "true",
				touchDrag: true,
				margin: 20,
				nav: true,
				dots: true,
				navText: [
					'<i class="fas fa-angle-left"></i>',
					'<i class="fas fa-angle-right"></i>',
				],
				navClass: ["owl-prev", "owl-next"],
				autoplay: owl.dataset.autoplay === "true",
				autoplayTimeout: owl.dataset.autoplaytimeout ?? 5000,
				responsive: {
					0: {
						items: 1,
					},
					769: {
						items: owl.dataset.columnes ?? 3,
					},
				},
			});
		});
	}
});
